// Highway
import Highway from '@dogstudio/highway';
import DefaultRenderer from '../pages/page.js';
import TestRenderer from '../pages/page-test.js';
import DefaultTransition from '../pages/transition.js';

import pagePrllx from './prllx.js'
import pageInView from './inview.js'
import pageAnchors from './anchors.js'

export function initRoutes(Header) {

   let viewPrllx;
   let viewInView;
   let viewAnchors;

   // Create Highway
   const H = new Highway.Core({
      renderers: {
         page: DefaultRenderer,
         pageTest: TestRenderer
      },
      transitions: {
         default: DefaultTransition
      }
   });

   H.on('NAVIGATE_OUT', (to, location) => {

      console.log('NAVIGATE_OUT');

      //RemovePrllx
      viewPrllx.destroy();

   });

   const links = Header.DOM.el.querySelectorAll('.Nav__link');
   H.on('NAVIGATE_IN', (to, location) => {

      console.log(to);

      //Menu active links
      links.forEach((link) => {
         link.classList.remove('is-active');
         if (link.href === location.href) {
           link.classList.add('is-active');
         }
      });

      initView(to.view);

   });

   H.on('NAVIGATE_END', (to, from, location) => {

      console.log('NAVIGATE_END');

      // Analytics
      /*if (typeof gtag !== 'undefined') {
       gtag('config', 'UA-XXXXXXXXX-X', {
         'page_path': location.pathname,
         'page_title': to.page.title,
         'page_location': location.href
       });
    }*/

   });

   function initView(container){

      document.body.scrollTop = document.documentElement.scrollTop = 0;

      //Menu Toggle
      if(Header.menuOpen){
         var click = new Event('click');
         Header.DOM.toggle.dispatchEvent(click);
       }

       //Check Anchors
       if (container.querySelector('.anchors__nav')) viewAnchors = new pageAnchors(container.querySelector('.anchors__nav'));

       //Prllx / InView
      viewPrllx = new pagePrllx(container);
      viewInView = new pageInView(container);

   }

   initView(document.body);

}
