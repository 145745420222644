import * as utils from '../utils.js';

class Footer {

   constructor(el) {

      this.DOM = {el: el};
      this.DOM.App = document.body.querySelector('.App');


      this.init();
      this.addEvents();
      this.updateFooterSize();

   }

   init() {

      console.log('init Footer');

   }

   updateFooterSize() {

      //this.DOM.App.style.marginBottom = this.DOM.el.offsetHeight + "px";

   }

   addEvents() {

      let resizeEvent = false;
      window.addEventListener('resize', () => {
        clearTimeout(resizeEvent);
        resizeEvent = setTimeout( this.updateFooterSize() , 500);
      });

   }

}

export default Footer;
