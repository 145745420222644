import { clamp } from '../utils.js';
import { normalize } from '../utils.js';

class pageAnchors {

     constructor(container) {

        this.DOM = {
           container : container
        };

        this.DOM.AnchorsLinks = this.DOM.container.querySelectorAll('.anchors__item');
        if (this.DOM.AnchorsLinks.length == 0) return;

        this.init();

      }

      init() {

       console.log('Init Anchors');

       this.DOM.AnchorsLinks.forEach((item) => {

          item.addEventListener('click', (e)=>{

             e.preventDefault();
             //anchors active
             if (this.DOM.container.querySelector('.is-active')) this.DOM.container.querySelector('.is-active').classList.remove('is-active');

             let targetAnchor = e.currentTarget.getAttribute("href");
             e.currentTarget.classList.add('is-active');
            TweenMax.to(window,1.2, {scrollTo:{y:document.querySelector(targetAnchor).offsetTop, autoKill:false}, ease:Power3.easeInOut});

          });
       });

      }

}

export default pageAnchors;
