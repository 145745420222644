import inView from 'in-view';

class pageInView {

     constructor(container) {

        this.DOM = {
           container : container
        };

       this.DOM.inviewItems = this.DOM.container.querySelectorAll('*[data-inview]');
       if (this.DOM.inviewItems.length == 0) return;

       this.init();

     }

     init() {

        console.log('Init inView');

        inView('*[data-inview]').on('enter', el => {
          el.classList.add('is-visible');
       }).on('exit', el => {
          el.classList.remove('is-visible');
       });

        //Inview Detection elements
        inView.offset({
           top: -120,
           left: -50,
           right: -50,
           bottom: 0,
        });

     }

}

export default pageInView;
