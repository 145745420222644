// Polyfill
import 'nodelist-foreach-polyfill';
import "babel-polyfill";
import 'whatwg-fetch';

import FastClick from 'modern-fastclick';
import * as utils from './utils.js';

import { initSniff } from './modules/sniffer.js';

import Header from './parts/header.js'
import Footer from './parts/footer.js'
import { initRoutes } from './modules/routes.js';


class App {

   constructor() {

	  //Declarations
	  this.sy = 0;
	  this.devMode = false;
	  this.DOM = {};
	  this.DOM.Body = document.body;
	  this.DOM.App = this.DOM.Body.querySelector('.App');
	  this.DOM.Header = this.DOM.Body.querySelector('.Header');
	  this.DOM.Footer = this.DOM.Body.querySelector('.Footer');
	  this.DOM.Loader = this.DOM.Body.querySelector('.Loader');

	  //Signature Wokine
	  console.log("%cCreated by Wokine, with ❤", "color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;"), console.log("%chttp://wokine.com", "color:#ccc")

	  this.init();
	  //this.addEvents();



   }

   init() {


	  //DevMode ( infos navigateur )
	  if (this.devMode) {
		 console.log('DEVMODE BIATCH');
	  }

	  //Smooth scroll
	document.querySelectorAll('a[href^="#"]').forEach(anchor => {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();

			document.querySelector(this.getAttribute('href')).scrollIntoView({
				behavior: 'smooth'
			});
		});
	});




	  //Chrome Scroll Manual
	  this.DOM.Body.scrollTop = document.documentElement.scrollTop = 0;
	  history.scrollRestoration = 'manual';

	  //DOMContentLoaded
	  document.addEventListener('DOMContentLoaded', () => {

		 new FastClick(document.body);
		 initSniff(this.devMode);
		 window.scrollTo(0, 0);
		 this.intro();

	  }, false);

	  // Create Header/Footer
	  const header = new Header(this.DOM.Header);
	  const footer = new Footer(this.DOM.Footer);

	  // Create Highway
	  initRoutes(header);

	  var form_newsletter = document.getElementById("newsletter_subscription");
	  var input_newsletter = document.getElementById("input_newsletter");
	  var result_newsletter = document.getElementById("result_newsletter");

	  if(form_newsletter.addEventListener){
		 form_newsletter.addEventListener("submit", onSubmit, false);  //Modern browsers
	  }else if(form_newsletter.attachEvent){
		 form_newsletter.attachEvent('onsubmit', onSubmit);            //Old IE
	  }

	  function onSubmit(e){
		 e.preventDefault();
		 var xhr = new XMLHttpRequest();
		 var url = '/ajax/register.php';
		 var params = 'email='+input_newsletter.value;

		 xhr.open('POST', url, true);
		 xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		 xhr.onreadystatechange = function() {//Call a function when the state changes.
			if(xhr.readyState == 4 && xhr.status == 200) {
			   result_newsletter.innerText = xhr.responseText;
			   result_newsletter.classList.remove("error");
			   result_newsletter.classList.add("success");
			} else {
			   result_newsletter.innerText = xhr.responseText;
			   result_newsletter.classList.remove("success");
			   result_newsletter.classList.add("error");
			}
		 }
		 xhr.send(params);
	  }

   }

   addEvents() {
	  window.addEventListener('scroll', (e) => {
		 this.sy = window.pageYOffset || document.documentElement.scrollTop;
	  })

   }

   intro() {

	  const intro = new TimelineMax({
		 paused: true,
		 onStart: () => {
			this.DOM.Body.classList.remove('is-first', 'is-loading');
		 },
		 onComplete: () => {
			this.DOM.Body.classList.remove('is-animating');
		 },
		 delay: 0.6
	  })

	  intro.fromTo(this.DOM.Loader.querySelector('.brand'), 1, { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1, ease: Power3.easeOut, clearProps: 'all' }, 0);
	  intro.fromTo(this.DOM.Loader.querySelector('.inner'), 1, { y: 0 }, { y: "75%", ease: Power3.easeInOut, clearProps: 'all' }, 0.8);
	  intro.fromTo(this.DOM.Loader, 1, { y: 0 }, { y: "-100%", ease: Power3.easeInOut, clearProps: 'all' }, 0.8);
	  intro.play();

   }


}

new App();
