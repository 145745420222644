import * as utils from '../utils.js';

class Header {

   constructor(el) {

      console.log('initHeader');

      this.DOM = {el: el};
      this.DOM.body = document.body;
      this.DOM.brand = this.DOM.el.querySelector('.brand');
      this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
      this.DOM.menuContainer = this.DOM.el.querySelector('Nav__container');
      this.menuOpen = false;

      this.init();

   }

   init() {

      //toggle
      this.DOM.toggle.addEventListener('click', (e) => {

         e.preventDefault();
         this.menuOpen == true ? this.close() : this.open();

      });

   }

   open() {

      return new Promise((resolve, reject) => {

         //console.log('Open Menu');
         this.menuOpen = true;
         this.DOM.body.classList.add('showMenu');
         this.DOM.body.style.overflow = "hidden";

      })

   }

   close() {

      return new Promise((resolve, reject) => {

         //console.log('Close Menu');
         this.menuOpen = false;
         this.DOM.body.classList.remove('showMenu');
         this.DOM.body.style.overflow = "";

      })

   }

}

export default Header;
