// Highway
import Highway from '@dogstudio/highway';

class DefaultTransition extends Highway.Transition {

   in (from, to, done) {

      let Loader = document.querySelector('.Loader');

      //Remove Last Page
      console.log(from);
      from.remove();

      //Timeline animation page enter
      const pageIn = new TimelineMax({
         paused: true,
         onStart: () => {
            document.body.classList.remove('is-loading');
         },
         onComplete: () => {
            document.body.classList.remove('is-animating');
            done();
         }
      })

      pageIn.fromTo(Loader.querySelector('.inner'), 1, { y: 0 }, { y: "-75%", ease: Power3.easeInOut, clearProps: 'all' }, 0);
      pageIn.fromTo(Loader, 1, { y: 0 }, { y: "-100%", ease: Power3.easeInOut, clearProps: 'all' }, 0);
      pageIn.fromTo(to, 1, { opacity: 0, y: 100 }, { opacity: 1, y: 0, ease: Power3.easeInOut, clearProps: 'all' }, 0);
      pageIn.play();

   }

   out(from, done) {

      let Loader = document.querySelector('.Loader');

      //Timeline animation page leave
      const pageOut = new TimelineMax({
         paused: true,
         onStart: () => {
            document.body.classList.add('is-loading');
            document.body.classList.add('is-animating');
         },
         onComplete: () => {
            done();
         }
      })

      pageOut.fromTo(Loader.querySelector('.inner'), 1, { y: "-75%" }, { y: "0%", ease: Power3.easeInOut }, 0);
      pageOut.fromTo(Loader, 1, { y: "100%" }, { y: "0%", ease: Power3.easeInOut }, 0);
      pageOut.to(from, 1, { opacity: 0, y: -20, ease: Power3.easeInOut, clearProps: 'all' }, 0);

      pageOut.play();

   }

}


export default DefaultTransition;
