// Highway
import Highway from '@dogstudio/highway';

class DefaultRenderer extends Highway.Renderer {

   onEnter() {

      console.log('New Page :', this.properties);
      console.log('DOM Page :', this.wrap);

   }

   onLeave() {

   }

}

export default DefaultRenderer;
