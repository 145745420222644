import Sniffer from 'snifferjs';

export function initSniff(devMode) {

   let Sniff = new Sniffer(navigator.userAgent),
      htmlNode = document.getElementsByTagName('html')[0],
      classNameArr = [htmlNode.className];

   Sniff.browser.name && classNameArr.push(Sniff.browser.name);
   Sniff.browser.engine && classNameArr.push(Sniff.browser.engine);
   Sniff.os.name && classNameArr.push(Sniff.os.name);

   for (var prop in Sniff.features) {
      if (Sniff.features[prop]) classNameArr.push(prop);
   }

   htmlNode.className = classNameArr.join(' ');

   if (devMode) {

      console.log('Sniffer :', Sniff);

   }

}
